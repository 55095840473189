import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import { CircularProgress } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import { Link } from "react-router-dom";

import LoginForm from "components/Forms/Login";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import firebase from "services/firebase";

import { useAddErrorAlert } from "hooks/alert/useAddErrorAlert";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const addErrorAlert = useAddErrorAlert();
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <LoginForm
            onSubmit={async (values, actions) => {
              try {
                actions.setSubmitting(true);
                await firebase
                  .auth()
                  .signInWithEmailAndPassword(values.email, values.password);
                actions.setSubmitting(false);
              } catch (error) {
                addErrorAlert(error);
                actions.setSubmitting(false);
              }
            }}
          >
            {formProps => {
              return (
                <Card login className={classes[cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="rose"
                  >
                    <h4 className={classes.cardTitle}>Log in</h4>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText={
                        formProps.touched &&
                        formProps.touched.email &&
                        formProps.errors &&
                        formProps.errors.email
                          ? formProps.errors.email
                          : "Email Address"
                      }
                      id="email"
                      error
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={
                        formProps.touched &&
                        formProps.touched.email &&
                        formProps.errors &&
                        formProps.errors.email
                          ? true
                          : false
                      }
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        ),
                        value: formProps.values.email,
                        onChange: formProps.handleChange("email"),
                        onBlur: formProps.handleBlur("email")
                      }}
                    />
                    <CustomInput
                      labelText={
                        formProps.touched &&
                        formProps.touched.password &&
                        formProps.errors &&
                        formProps.errors.password
                          ? formProps.errors.password
                          : "Password"
                      }
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      error={
                        formProps.touched &&
                        formProps.touched.password &&
                        formProps.errors &&
                        formProps.errors.password
                          ? true
                          : false
                      }
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                            </Icon>
                          </InputAdornment>
                        ),
                        type: "password",
                        autoComplete: "off",
                        value: formProps.values.password,
                        onChange: formProps.handleChange("password"),
                        onBlur: formProps.handleBlur("password")
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button
                      color="rose"
                      simple
                      size="lg"
                      block
                      onClick={() => {
                        formProps.submitForm();
                      }}
                      className={classes.loginButton}
                    >
                      {formProps.isSubmitting ? (
                        <CircularProgress color="rose" size={15} />
                      ) : (
                        `Let's go`
                      )}
                    </Button>

                    <CardFooter>
                      <Link to="/anonymous/forgot-password">
                        Forgot password?
                      </Link>
                      <Link to="/anonymous/register">
                        Don't have an account?
                      </Link>
                    </CardFooter>
                  </CardFooter>
                </Card>
              );
            }}
          </LoginForm>
        </GridItem>
      </GridContainer>
    </div>
  );
}
