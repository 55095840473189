import React, { useState } from "react";
import AddListForm from "components/Forms/Lists";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  InputAdornment,
  Select,
  MenuItem,
  CircularProgress
} from "@material-ui/core";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_ADD_FILTER_DATA } from "graphql/queries/addFilter";
import { GET_LISTS } from "graphql/queries/lists";

import { ADD_FILTER } from "graphql/mutations/filter";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  item: {
    padding: "1em",
    width: "500px"
  },
  container: {
    width: "400px"
  },
  formControl: {
    width: "100%",
    marginBottom: "10px"
  }
});

const generateDistanceOptions = maxDistance => {
  const size = Math.round(maxDistance / 5);
  let res = [];
  for (let x = 0; x < size; x++) {
    res.push((x + 1) * 5);
  }
  return res;
};

const distanceMenuItem = maxDistance => {
  return generateDistanceOptions(maxDistance).map(item => {
    return <MenuItem value={item}>{item}</MenuItem>;
  });
};

const Add = () => {
  const history = useHistory();
  const classes = useStyles();
  const {
    loading: addFilterDataLoading,
    data: addFilterData,
    error: addFilterDataError
  } = useQuery(GET_ADD_FILTER_DATA);
  const [addFilterMutation] = useMutation(ADD_FILTER);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <AddListForm
          onSubmit={async (values, actions) => {
            console.log(values);
            try {
              actions.setSubmitting(true);
              await addFilterMutation({
                variables: {
                  name: values.name,
                  distance: values.distance,
                  location: addFilterData.location[0].id,
                  frequency: values.frequency,
                  min_price: values.min_price,
                  max_price: values.max_price,
                  property_type_id: values.property_types,
                  listing_type_id: values.listing_types
                },
                refetchQueries: [{ query: GET_LISTS }]
              });
              actions.setSubmitting(false);
              history.push("admin/lists");
            } catch (error) {
              actions.setSubmitting(false);
            }
          }}
        >
          {formProps => {
            return (
              <React.Fragment>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                      Add New List
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="location">Location</InputLabel>
                      <Input
                        id="location"
                        value={
                          addFilterData &&
                          addFilterData.location &&
                          addFilterData.location[0] &&
                          addFilterData.location[0].address
                            ? addFilterData.location[0].address
                            : ""
                        }
                        disabled
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="name">List Name</InputLabel>
                      <Input
                        id="name"
                        aria-describedby="name-helper-text"
                        value={formProps.values.name}
                        onChange={formProps.handleChange("name")}
                        onBlur={formProps.handleBlur("name")}
                        error={
                          formProps.touched &&
                          formProps.touched.name &&
                          formProps.errors &&
                          formProps.errors.name
                            ? true
                            : false
                        }
                      />
                      {formProps.touched &&
                      formProps.touched.name &&
                      formProps.errors &&
                      formProps.errors.name ? (
                        <FormHelperText id="name-helper-text" error>
                          {formProps.errors.name}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="label-distance" htmlFor="distance">
                        Radius Distance in Miles
                      </InputLabel>
                      <Select
                        labelId="label-distance"
                        id="distance"
                        value={formProps.values.distance}
                        onChange={formProps.handleChange("distance")}
                        onBlur={formProps.handleBlur("distance")}
                        error={
                          formProps.touched &&
                          formProps.touched.distance &&
                          formProps.errors &&
                          formProps.errors.distance
                            ? true
                            : false
                        }
                      >
                        {distanceMenuItem(
                          addFilterData &&
                            addFilterData.location &&
                            addFilterData.location[0] &&
                            addFilterData.location[0].plan &&
                            addFilterData.location[0].plan &&
                            addFilterData.location[0].plan.distance_in_miles
                            ? addFilterData.location[0].plan.distance_in_miles
                            : 0
                        )}
                      </Select>
                      {formProps.touched &&
                      formProps.touched.distance &&
                      formProps.errors &&
                      formProps.errors.distance ? (
                        <FormHelperText id="distance-helper-text" error>
                          {formProps.errors.distance}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="label-min_price" htmlFor="min_price">
                        Minimum Property Price
                      </InputLabel>
                      <Select
                        labelId="label-min_price"
                        id="min_price"
                        value={formProps.values.min_price}
                        onChange={formProps.handleChange("min_price")}
                        onBlur={formProps.handleBlur("min_price")}
                        error={
                          formProps.touched &&
                          formProps.touched.min_price &&
                          formProps.errors &&
                          formProps.errors.min_price
                            ? true
                            : false
                        }
                      >
                        <MenuItem value={0}>$0</MenuItem>
                        <MenuItem value={50000}>$50,000</MenuItem>
                        <MenuItem value={100000}>$100,000</MenuItem>
                        <MenuItem value={150000}>$150,000</MenuItem>
                        <MenuItem value={200000}>$200,000</MenuItem>
                        <MenuItem value={300000}>$300,000</MenuItem>
                        <MenuItem value={400000}>$400,000</MenuItem>
                        <MenuItem value={500000}>$500,000</MenuItem>
                        <MenuItem value={600000}>$600,000</MenuItem>
                        <MenuItem value={700000}>$700,000</MenuItem>
                        <MenuItem value={800000}>$800,000</MenuItem>
                        <MenuItem value={1000000}>$1,000,000</MenuItem>
                        <MenuItem value={1500000}>$1,500,000</MenuItem>
                        <MenuItem value={2000000}>$2,000,000</MenuItem>
                        <MenuItem value={3000000}>$3,000,000</MenuItem>
                        <MenuItem value={5000000}>$5,000,000</MenuItem>
                        <MenuItem value={7500000}>$7,500,000</MenuItem>
                        <MenuItem value={10000000}>$10,000,000</MenuItem>
                      </Select>
                      {formProps.touched &&
                      formProps.touched.min_price &&
                      formProps.errors &&
                      formProps.errors.min_price ? (
                        <FormHelperText id="min_price-helper-text" error>
                          {formProps.errors.min_price}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="label-max_price" htmlFor="max_price">
                        Maximum Property Price
                      </InputLabel>
                      <Select
                        labelId="label-max_price"
                        id="max_price"
                        value={formProps.values.max_price}
                        onChange={formProps.handleChange("max_price")}
                        onBlur={formProps.handleBlur("max_price")}
                        error={
                          formProps.touched &&
                          formProps.touched.max_price &&
                          formProps.errors &&
                          formProps.errors.max_price
                            ? true
                            : false
                        }
                      >
                        <MenuItem value={100000}>$100,000</MenuItem>
                        <MenuItem value={150000}>$150,000</MenuItem>
                        <MenuItem value={200000}>$200,000</MenuItem>
                        <MenuItem value={300000}>$300,000</MenuItem>
                        <MenuItem value={400000}>$400,000</MenuItem>
                        <MenuItem value={500000}>$500,000</MenuItem>
                        <MenuItem value={600000}>$600,000</MenuItem>
                        <MenuItem value={700000}>$700,000</MenuItem>
                        <MenuItem value={800000}>$800,000</MenuItem>
                        <MenuItem value={1000000}>$1,000,000</MenuItem>
                        <MenuItem value={1500000}>$1,500,000</MenuItem>
                        <MenuItem value={2000000}>$2,000,000</MenuItem>
                        <MenuItem value={3000000}>$3,000,000</MenuItem>
                        <MenuItem value={5000000}>$5,000,000</MenuItem>
                        <MenuItem value={7500000}>$7,500,000</MenuItem>
                        <MenuItem value={0}>$10,000,000+</MenuItem>
                      </Select>
                      {formProps.touched &&
                      formProps.touched.max_price &&
                      formProps.errors &&
                      formProps.errors.max_price ? (
                        <FormHelperText id="max_price-helper-text" error>
                          {formProps.errors.max_price}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        id="label-property_types"
                        htmlFor="property_types"
                      >
                        Property Type
                      </InputLabel>
                      <Select
                        labelId="label-property_types"
                        id="property_types"
                        value={formProps.values.property_types}
                        input={<Input />}
                        onChange={formProps.handleChange("property_types")}
                        onBlur={formProps.handleBlur("property_types")}
                        error={
                          formProps.touched &&
                          formProps.touched.property_types &&
                          formProps.errors &&
                          formProps.errors.property_types
                            ? true
                            : false
                        }
                      >
                        {addFilterData && addFilterData.property_type ? (
                          addFilterData.property_type
                            .filter(
                              item =>
                                item.country ===
                                addFilterData.location[0].country
                            )
                            .map(item => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                            })
                        ) : (
                          <div />
                        )}
                      </Select>
                      {formProps.touched &&
                      formProps.touched.property_types &&
                      formProps.errors &&
                      formProps.errors.property_types ? (
                        <FormHelperText id="property_types-helper-text" error>
                          {formProps.errors.property_types}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        id="label-listing_types"
                        htmlFor="listing_types"
                      >
                        Listing Type
                      </InputLabel>
                      <Select
                        labelId="label-listing_types"
                        id="listing_types"
                        input={<Input />}
                        value={formProps.values.listing_types}
                        onChange={formProps.handleChange("listing_types")}
                        onBlur={formProps.handleBlur("listing_types")}
                        error={
                          formProps.touched &&
                          formProps.touched.listing_types &&
                          formProps.errors &&
                          formProps.errors.listing_types
                            ? true
                            : false
                        }
                      >
                        {addFilterData && addFilterData.listing_type ? (
                          addFilterData.listing_type
                            .filter(
                              item =>
                                item.country ===
                                addFilterData.location[0].country
                            )
                            .map(item => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                            })
                        ) : (
                          <div />
                        )}
                      </Select>
                      {formProps.touched &&
                      formProps.touched.listing_types &&
                      formProps.errors &&
                      formProps.errors.listing_types ? (
                        <FormHelperText id="listing_types-helper-text" error>
                          {formProps.errors.listing_types}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="label-frequency" htmlFor="frequency">
                        Frequency the list will be generated
                      </InputLabel>
                      <Select
                        labelId="label-frequency"
                        id="frequency"
                        value={formProps.values.frequency}
                        onChange={formProps.handleChange("frequency")}
                        onBlur={formProps.handleBlur("frequency")}
                        error={
                          formProps.touched &&
                          formProps.touched.frequency &&
                          formProps.errors &&
                          formProps.errors.frequency
                            ? true
                            : false
                        }
                      >
                        {addFilterData && addFilterData.frequency ? (
                          addFilterData.frequency.map(item => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })
                        ) : (
                          <div />
                        )}
                      </Select>
                      {formProps.touched &&
                      formProps.touched.frequency &&
                      formProps.errors &&
                      formProps.errors.frequency ? (
                        <FormHelperText id="frequency-helper-text" error>
                          {formProps.errors.frequency}
                        </FormHelperText>
                      ) : (
                        <div />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    {formProps.isSubmitting ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        onClick={() => {
                          formProps.submitForm();
                        }}
                      >
                        Save List
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          }}
        </AddListForm>
      </Grid>
    </Grid>
  );
};

export default Add;
