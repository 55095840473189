import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { client } from "graphql/client";
import Alerts from "components/Alert/Alerts";

import AnonymousLayout from "layouts/AnonymousLayout";
import AdminLayout from "layouts/Admin";

const hist = createBrowserHistory();

const App = () => (
  <ApolloProvider client={client}>
    <Alerts />
    <Router history={hist}>
      <Switch>
        <Route path="/anonymous" component={AnonymousLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </Router>
  </ApolloProvider>
);

export default App;
