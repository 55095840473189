import React from "react";
import ReactTable from "react-table";
//import { useHistory } from "react-router-dom";

const Table = ({ data }: { data: any }) => {
  //const history = useHistory();

  return (
    <ReactTable
      defaultPageSize={data && data.length > 50 ? 50 : data.length}
      showPaginationBottom={data && data.length > 50 ? true : false}
      columns={[
        {
          Header: "Number",
          accessor: "number"
        },
        {
          Header: "forward_to",
          accessor: "forward_to"
        },
        {
          Header: "Calls in last 24 hours",
          accessor: "day"
        },
        {
          Header: "Calls in the last week",
          accessor: "week"
        },
        {
          Header: "Calls in the last month",
          accessor: "month"
        },
        {
          Header: "",
          accessor: "actions"
        }
      ]}
      data={data.map(data => {
        console.log(data);
        return {
          number: data.number,
          forward_to: data.forward_to,
          day: data.stats.day,
          week: data.stats.week,
          month: data.stats.month
        };
      })}
    />
  );
};

export default Table;
