import * as yup from "yup";

export const initialValues = {
  name: "",
  min_price: 0,
  max_price: 0,
  distance: 70,
  frequency: "",
  property_types: "",
  listing_types: ""
};

export const validationSchema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  min_price: yup.number().required(),
  max_price: yup.number().required(),
  distance: yup.number().required(),
  frequency: yup.string().required(),
  property_types: yup.string().required(),
  listing_types: yup.string().required()
});
