import gql from "graphql-tag";

export const GET_LISTS = gql`
  query {
    filter {
      id
      name
      location {
        address
      }
      min_price
      max_price
      distance
      frequency {
        name
      }
      property_types {
        property_type {
          name
        }
      }
      listing_types {
        listing_type {
          name
        }
      }
      last_generated_filter_listing_result {
        created_at
      }
      next_execution_at
    }
  }
`;

export const GET_GENERATED_LIST = gql`
  query($id: uuid!) {
    filter_listing_results(where: { filter: { id: { _eq: $id } } }) {
      id
      location {
        address
      }
      applied_filter
      created_at
      listings_total
      filter {
        name
      }
    }
  }
`;
