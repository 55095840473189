import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyDmU5X4WvaAyi2lUS9AbfWpQE6gsIj9l78",
  authDomain: "movingshortly-dev.firebaseapp.com",
  databaseURL: "https://movingshortly-dev.firebaseio.com",
  projectId: "movingshortly-dev",
  storageBucket: "movingshortly-dev.appspot.com",
  messagingSenderId: "872159371262",
  appId: "1:872159371262:web:2fa146b22956166ac6aaaf",
  measurementId: "G-9HX6NB5CFN"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.analytics();

export default firebaseApp;
