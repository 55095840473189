import gql from "graphql-tag";

export const ADD_FILTER = gql`
  mutation(
    $name: String!
    $distance: bigint!
    $location: uuid!
    $frequency: uuid!
    $min_price: bigint!
    $max_price: bigint!
    $property_type_id: uuid!
    $listing_type_id: uuid!
  ) {
    insert_filter(
      objects: [
        {
          name: $name
          distance: $distance
          location_id: $location
          min_price: $min_price
          max_price: $max_price
          property_types: { data: { property_type_id: $property_type_id } }
          listing_types: { data: { listing_type_id: $listing_type_id } }
          frequency_id: $frequency
        }
      ]
    ) {
      affected_rows
    }
  }
`;

export const DELETE_FILTER = gql`
  mutation($id: uuid!) {
    delete_filter(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
