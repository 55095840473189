import Dashboard from "views/Dashboard/Dashboard.js";
import PricingPage from "views/Pages/PricingPage.js";

import LoginPage from "views/Pages/LoginPage.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import ForgotPasswordPage from "views/Pages/ForgotPasswordPage.js";
import ListsPage from "views/Pages/ListsPage";
import NumbersPage from "views/Pages/NumbersPage";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListIcon from "@material-ui/icons/List";
import MapIcon from "@material-ui/icons/Map";
import SettingsIcon from "@material-ui/icons/Settings";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import DialpadIcon from "@material-ui/icons/Dialpad";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/lists",
    name: "Lists",
    rtlName: "",
    icon: ListIcon,
    component: ListsPage,
    layout: "/admin"
  },
  {
    path: "/numbers",
    name: "Tracking Numbers",
    rtlName: "",
    icon: DialpadIcon,
    component: NumbersPage,
    layout: "/admin"
  },
  {
    name: "Settings",
    collapse: true,
    rtlName: "",
    icon: SettingsIcon,
    state: "pageCollapse",
    views: [
      {
        path: "/settings/profile",
        name: "Profile",
        rtlName: "",
        icon: PersonIcon,
        rtlMini: "",
        component: Dashboard,
        layout: "/admin"
      },
      {
        path: "/settings/locations",
        name: "Locations",
        rtlName: "",
        icon: BusinessIcon,
        rtlMini: "",
        component: Dashboard,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    rtlName: "",
    icon: BusinessIcon,
    rtlMini: "",
    component: LoginPage,
    layout: "/anonymous"
  },
  {
    path: "/register",
    name: "Register",
    rtlName: "",
    icon: BusinessIcon,
    rtlMini: "",
    component: RegisterPage,
    layout: "/anonymous"
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    rtlName: "",
    icon: BusinessIcon,
    rtlMini: "",
    component: ForgotPasswordPage,
    layout: "/anonymous"
  },
  {
    path: "/pricing",
    name: "Pricing",
    rtlName: "",
    icon: BusinessIcon,
    rtlMini: "",
    component: PricingPage,
    layout: "/anonymous"
  }
];
export default dashRoutes;
