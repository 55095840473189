import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import Code from "@material-ui/icons/Code";
import Group from "@material-ui/icons/Group";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import { CircularProgress } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { SIGN_UP } from "graphql/mutations/account";

import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

import RegisterForm from "components/Forms/Signup";
import Grid from "@material-ui/core/Grid";

import ReactDependentScript from "react-dependent-script";
import GooglePlacesAutocomplete from "components/GooglePlacesAutocomplete";
import { useAddErrorAlert } from "hooks/alert/useAddErrorAlert";
import firebase from "services/firebase";

const useStyles = makeStyles(styles);

export default function RegisterPage() {
  const addErrorAlert = useAddErrorAlert();
  const [signupMutation] = useMutation(SIGN_UP);
  const [checked, setChecked] = React.useState([]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <Card className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>Register</h2>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={5}>
                  <InfoArea
                    title="Feature 1"
                    description="tThis is a feature we want to highlight"
                    icon={Timeline}
                    iconColor="rose"
                  />
                  <InfoArea
                    title="Feature 2"
                    description="tThis is a feature we want to highlight"
                    icon={Code}
                    iconColor="primary"
                  />
                  <InfoArea
                    title="Feature 3"
                    description="tThis is a feature we want to highlight"
                    icon={Group}
                    iconColor="info"
                  />
                </GridItem>
                <GridItem xs={12} sm={8} md={5}>
                  <RegisterForm
                    onSubmit={async (values, actions) => {
                      try {
                        actions.setSubmitting(true);
                        await signupMutation({
                          variables: {
                            organization: values.organization,
                            email: values.email,
                            password: values.password,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            formatted_address: values.address.formatted_address,
                            latitude: values.address.latitude,
                            longitude: values.address.longitude,
                            place_id: values.address.place_id,
                            types: values.address.types,
                            address_components:
                              values.address.address_components
                          }
                        });
                        await firebase
                          .auth()
                          .signInWithEmailAndPassword(
                            values.email,
                            values.password
                          );
                        actions.setSubmitting(false);
                      } catch (error) {
                        addErrorAlert(error);
                        actions.setSubmitting(false);
                      }
                    }}
                  >
                    {formProps => {
                      return (
                        <ReactDependentScript
                          loadingComponent={<CircularProgress />}
                          scripts={[
                            `https://maps.googleapis.com/maps/api/js?key=AIzaSyAWYbQQj1sjTbHzKcQY7qak35H5J_cVFAs&libraries=places`
                          ]}
                        >
                          <CustomInput
                            labelText={
                              formProps.touched &&
                              formProps.touched.organization &&
                              formProps.errors &&
                              formProps.errors.organization
                                ? formProps.errors.organization
                                : "Company Name"
                            }
                            id="organization"
                            error
                            formControlProps={{
                              fullWidth: true
                            }}
                            error={
                              formProps.touched &&
                              formProps.touched.organization &&
                              formProps.errors &&
                              formProps.errors.organization
                                ? true
                                : false
                            }
                            inputProps={{
                              // endAdornment: (
                              //   <InputAdornment position="end">
                              //     <Email
                              //       className={classes.inputAdornmentIcon}
                              //     />
                              //   </InputAdornment>
                              // ),
                              value: formProps.values.organization,
                              onChange: formProps.handleChange("organization"),
                              onBlur: formProps.handleBlur("organization")
                            }}
                          />
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <CustomInput
                                labelText={
                                  formProps.touched &&
                                  formProps.touched.firstName &&
                                  formProps.errors &&
                                  formProps.errors.firstName
                                    ? formProps.errors.firstName
                                    : "First Name"
                                }
                                id="firstName"
                                error
                                formControlProps={{
                                  fullWidth: true
                                }}
                                error={
                                  formProps.touched &&
                                  formProps.touched.firstName &&
                                  formProps.errors &&
                                  formProps.errors.firstName
                                    ? true
                                    : false
                                }
                                inputProps={{
                                  // endAdornment: (
                                  //   <InputAdornment position="end">
                                  //     <Email
                                  //       className={classes.inputAdornmentIcon}
                                  //     />
                                  //   </InputAdornment>
                                  // ),
                                  value: formProps.values.firstName,
                                  onChange: formProps.handleChange("firstName"),
                                  onBlur: formProps.handleBlur("firstName")
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CustomInput
                                labelText={
                                  formProps.touched &&
                                  formProps.touched.lastName &&
                                  formProps.errors &&
                                  formProps.errors.lastName
                                    ? formProps.errors.lastName
                                    : "Last Name"
                                }
                                id="lastName"
                                error
                                formControlProps={{
                                  fullWidth: true
                                }}
                                error={
                                  formProps.touched &&
                                  formProps.touched.lastName &&
                                  formProps.errors &&
                                  formProps.errors.lastName
                                    ? true
                                    : false
                                }
                                inputProps={{
                                  // endAdornment: (
                                  //   <InputAdornment position="end">
                                  //     <Email
                                  //       className={classes.inputAdornmentIcon}
                                  //     />
                                  //   </InputAdornment>
                                  // ),
                                  value: formProps.values.lastName,
                                  onChange: formProps.handleChange("lastName"),
                                  onBlur: formProps.handleBlur("lastName")
                                }}
                              />
                            </Grid>
                          </Grid>
                          <GooglePlacesAutocomplete
                            label="Please type your address, to start the search"
                            placeholder=""
                            error={
                              formProps.touched &&
                              formProps.touched.address &&
                              formProps.errors &&
                              formProps.errors.address &&
                              formProps.errors.address.formatted_address
                                ? true
                                : false
                            }
                            helperText={
                              formProps.touched &&
                              formProps.touched.address &&
                              formProps.errors &&
                              formProps.errors.address &&
                              formProps.errors.address.formatted_address
                                ? formProps.errors.address.formatted_address
                                : ""
                            }
                            value={
                              formProps.values.address &&
                              formProps.values.address.hasOwnProperty(
                                "formatted_address"
                              ) &&
                              formProps.values.address.hasOwnProperty(
                                "place_id"
                              )
                                ? {
                                    label:
                                      formProps.values.address
                                        .formatted_address,
                                    value: formProps.values.address.place_id
                                  }
                                : undefined
                            }
                            onOptionClick={value => {
                              if (value) {
                                formProps.setFieldValue("address", {
                                  address_components: value.address_components,
                                  formatted_address: value.formatted_address.toString(),
                                  latitude: value.geometry.location.lat(),
                                  longitude: value.geometry.location.lng(),
                                  name: value.name.toString(),
                                  place_id: value.place_id.toString(),
                                  types: value.types
                                });
                              } else {
                                formProps.setFieldValue("address", {});
                              }
                            }}
                            onFocus={() => {
                              formProps.setFieldValue("address", {});
                            }}
                          />
                          <CustomInput
                            labelText={
                              formProps.touched &&
                              formProps.touched.email &&
                              formProps.errors &&
                              formProps.errors.email
                                ? formProps.errors.email
                                : "Email Address"
                            }
                            id="email"
                            error
                            formControlProps={{
                              fullWidth: true
                            }}
                            error={
                              formProps.touched &&
                              formProps.touched.email &&
                              formProps.errors &&
                              formProps.errors.email
                                ? true
                                : false
                            }
                            inputProps={{
                              // endAdornment: (
                              //   <InputAdornment position="end">
                              //     <Email
                              //       className={classes.inputAdornmentIcon}
                              //     />
                              //   </InputAdornment>
                              // ),
                              value: formProps.values.email,
                              onChange: formProps.handleChange("email"),
                              onBlur: formProps.handleBlur("email")
                            }}
                          />
                          <CustomInput
                            labelText={
                              formProps.touched &&
                              formProps.touched.password &&
                              formProps.errors &&
                              formProps.errors.password
                                ? formProps.errors.password
                                : "Password"
                            }
                            id="password"
                            formControlProps={{
                              fullWidth: true
                            }}
                            error={
                              formProps.touched &&
                              formProps.touched.password &&
                              formProps.errors &&
                              formProps.errors.password
                                ? true
                                : false
                            }
                            inputProps={{
                              // endAdornment: (
                              //   <InputAdornment position="end">
                              //     <Icon className={classes.inputAdornmentIcon}>
                              //       lock_outline
                              //     </Icon>
                              //   </InputAdornment>
                              // ),
                              type: "password",
                              autoComplete: "off",
                              value: formProps.values.password,
                              onChange: formProps.handleChange("password"),
                              onBlur: formProps.handleBlur("password")
                            }}
                          />

                          <Button
                            color="rose"
                            simple
                            size="lg"
                            block
                            onClick={() => {
                              formProps.submitForm();
                            }}
                            className={classes.loginButton}
                          >
                            {formProps.isSubmitting ? (
                              <CircularProgress color="rose" size={15} />
                            ) : (
                              `Register`
                            )}
                          </Button>
                        </ReactDependentScript>
                      );
                    }}
                  </RegisterForm>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
