import * as yup from "yup";

export const initialValues = {
  organization: "",
  firstName: "",
  lastName: "",
  email: "",
  address: {},
  password: ""
};

export const validationSchema = yup.object().shape({
  organization: yup.string().required("Company name is a required field"),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required(),
  address: yup.object().shape({
    formatted_address: yup
      .string()
      .required(
        "Select an option from the dropdown. Type our address to start the search..."
      ),
    place_id: yup.string().required()
  }),
  password: yup
    .string()
    .required()
    .min(6)
});
