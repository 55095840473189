// export const graphqlUrl =
//   process.env.NODE_ENV === "development"
//     ? "https://devapi.movingshortly.com/v1alpha1/graphql"
//     : "https://api.movingshortly.com/v1alpha1/graphql";
// export const subscriptionUrl =
//   process.env.NODE_ENV === "development"
//     ? "wss://devapi.movingshortly.com/v1alpha1/graphql"
//     : "wss://api.movingshortly.com/v1alpha1/graphql";

export const graphqlUrl = "https://devapi.movingshortly.com/v1alpha1/graphql";

export const subscriptionUrl =
  "wss://devapi.movingshortly.com/v1alpha1/graphql";
