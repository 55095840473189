import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_ALERT } from "../../graphql/mutations/alert";

type AlertProps = {
  id: String;
  type:
    | "default"
    | "info"
    | "success"
    | "warning"
    | "danger"
    | "error"
    | "input"
    | "custom";
  title: String;
  message: String;
};

const Alert = ({ id, type, title, message }: AlertProps) => {
  const [deleteAlertMutation] = useMutation(DELETE_ALERT);
  return (
    <SweetAlert
      type={type}
      style={{ display: "block", marginTop: "-100px" }}
      title={title}
      onConfirm={() => deleteAlertMutation({ variables: { id } })}
      onCancel={() => deleteAlertMutation({ variables: { id } })}
    >
      {message}
    </SweetAlert>
  );
};

export default Alert;
