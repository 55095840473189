import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Button,
  Grid,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  InputAdornment,
  Select,
  MenuItem,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from "@material-ui/core";

const useStyles = makeStyles({
  item: {
    padding: "1em",
    width: "500px"
  },
  container: {
    width: "400px"
  },
  formControl: {
    width: "100%",
    marginBottom: "10px"
  }
});

const ForwardTo = ({ setNumberState, numberState }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Typography>
            {`All calls from ${numberState.e164} will be forward to:`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <InputLabel id="label-forwardTo" htmlFor="forwardTo">
              Forward to:
            </InputLabel>
            <Input
              labelId="label-forwardTo"
              id="forwardTo"
              value={numberState.forwardTo}
              onChange={e =>
                setNumberState({
                  ...numberState,
                  forwardTo: e.target.value
                })
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ForwardTo;
