type token = {
  token: string | undefined
  set: (newToken: string | undefined) => void
  get: () => string | undefined
}

const token: token = {
  token: undefined,
  set: newToken => {
    token.token = newToken
  },
  get: () => {
    return token.token
  }
}

export default token
