import gql from "graphql-tag";

export const GET_PROPERTY_STATS = gql`
  query {
    stats_listing_canada {
      day
      week
      month
    }
    stats_listing_us {
      day
      week
      month
    }
  }
`;
