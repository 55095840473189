import React from "react";
import authToken from "../../graphql/token";

export const useIsAuthenticated = ({ firebaseClient }) => {
  const [auth, setAuth] = React.useState({
    isAuthenticated: false,
    isRequested: false
  });
  React.useEffect(() => {
    firebaseClient.auth().onAuthStateChanged(async user => {
      if (user) {
        const newToken = await user.getIdToken();
        if (newToken) {
          authToken.set(newToken);
        }
        setAuth({
          isAuthenticated: true,
          isRequested: true
        });
      } else {
        setAuth({
          isAuthenticated: false,
          isRequested: true
        });
      }
    });
  }, [auth.isAuthenticated, auth.isRequested]);
  return auth;
};
