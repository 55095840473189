import React, { useState } from "react";
import ReactTable from "react-table";
import { Button, Chip, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { GET_LISTS } from "graphql/queries/lists";
import { DELETE_FILTER } from "graphql/mutations/filter";

const Table = ({ data }: { data: any }) => {
  const history = useHistory();
  const [deleteFilterMutation] = useMutation(DELETE_FILTER);
  const [deletingFilter, setDeletingFiter] = useState(false);
  const deleteFilter = async id => {
    setDeletingFiter(true);
    await deleteFilterMutation({
      variables: { id },
      refetchQueries: [{ query: GET_LISTS }]
    });
    setDeletingFiter(false);
  };
  return (
    <ReactTable
      defaultPageSize={data && data.length > 50 ? 50 : data.length}
      showPaginationBottom={data && data.length > 50 ? true : false}
      columns={[
        {
          Header: "Name",
          accessor: "name"
        },
        {
          Header: "Location",
          accessor: "location"
        },
        {
          Header: "Filters",
          accessor: "filters"
        },
        {
          Header: "Last Executed",
          accessor: "last_executed"
        },
        {
          Header: "Next Execution",
          accessor: "next_execution"
        },
        {
          Header: "",
          accessor: "actions"
        }
      ]}
      data={data.map(data => {
        console.log(data);
        return {
          location: data.location.address,
          name: data.name,
          filters: (
            <React.Fragment>
              <Chip
                variant="outlined"
                size="small"
                label={`Distance: ${data.distance} miles`}
              />
              <Chip
                variant="outlined"
                size="small"
                label={`Min Price: ${data.min_price}`}
              />
              <Chip
                variant="outlined"
                size="small"
                label={`Max Price: ${
                  data.max_price === 0 ? "Unlimited" : data.max_price
                }`}
              />
              <Chip
                variant="outlined"
                size="small"
                label={`Property Types: ${data.property_types
                  .map(item => item.property_type.name)
                  .join(",")}`}
              />

              <Chip
                variant="outlined"
                size="small"
                label={`Listing Types: ${data.listing_types
                  .map(item => item.listing_type.name)
                  .join(",")}`}
              />
              <Chip
                variant="outlined"
                size="small"
                label={`Frequency: ${data.frequency.name}`}
              />
            </React.Fragment>
          ),
          last_executed:
            data.last_generated_filter_listing_result &&
            data.last_generated_filter_listing_result.created_at
              ? data.last_generated_filter_listing_result.created_at
              : "N/A",
          next_execution: data.next_execution_at
            ? data.next_execution_at
            : "N/A",
          actions: (
            <React.Fragment>
              <Button
                color="primary"
                onClick={() => {
                  history.push(`/admin/lists/view/${data.id}`);
                }}
              >
                Show Generated Lists
              </Button>
              {deletingFilter ? (
                <CircularProgress />
              ) : (
                <Button
                  color="secondary"
                  onClick={() => {
                    deleteFilter(data.id);
                  }}
                >
                  Delete
                </Button>
              )}
            </React.Fragment>
          )
        };
      })}
    />
  );
};

export default Table;
