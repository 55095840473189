import gql from "graphql-tag";

export const GET_NUMBERS = gql`
  query {
    number {
      id
      number
      forward_to
      stats {
        day
        week
        month
      }
    }
  }
`;

export const SEARCH_AVAILABLE_NUMBERS = gql`
  query($city: String!, $state: String!) {
    search_available_numbers(city: $city, state: $state) {
      e164
      international_number_formatted
      national_number_formatted
    }
  }
`;
