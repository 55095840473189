import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import NoNumbersImage from "assets/img/tracking.jpg";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  card: { width: "100%" }
});

const EmptyList = ({}) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Add new Number"
          height="200"
          image={NoNumbersImage}
          title="Add new number"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Numbers
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            No tracking numbers were created yet. Create new numbers to track
            your leads.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          color="secondary"
          onClick={() => {
            history.push("/admin/numbers/add");
          }}
        >
          Create New Number
        </Button>
      </CardActions>
    </Card>
  );
};

export default EmptyList;
